<template>
  <div>
    <div v-if="showBattle">
      <Battle :enemy="currentEnemy" :gamestate="gamestate" @editGameState="editGameState" :currentBiome="currentBiome" />
    </div>
    <div v-else :class="{ 'size-100': !minimap, 'no-movement': gamestate.movement == 0 }">

      <div v-if="minimap == false" class="map">
        <div>
          <div class="map-container" :style="mapContainerStyle">
            <div class="planet-map"
              :style="{ 'grid-template-columns': 'repeat(' + mapSize + ', 64px)', 'grid-template-rows': 'repeat(' + mapSize + ', 64px)' }">
              <div v-for="(row, rowIdx) in map" :key="rowIdx" class="map-row">
                <div v-for="(tile, colIdx) in row" :key="colIdx" :class="[
                  'map-tile',
                  tile.biome,
                  { 'highlighted': gamestate.movement > 0 && isHighlightedTile(rowIdx, colIdx) },
                  { 'visited': isTileVisited(rowIdx, colIdx) },
                  { 'enemy-tile': isEnemyTile(rowIdx, colIdx) },
                  { 'current': isPlayerTile(rowIdx, colIdx) },
                  { 'moveable': activeTile === `${rowIdx}-${colIdx}` },
                ]" @click="handleTileClick(rowIdx, colIdx)"
                  :id="'tooltip-' + getEnemyType(rowIdx, colIdx) + rowIdx + colIdx" tabindex="0">
                  <div v-if="isTileVisited(rowIdx, colIdx) || isEnemyTile(rowIdx, colIdx)">

                    <div v-if="collectibles[rowIdx][colIdx] && collectibles[rowIdx][colIdx].collected == false">
                      <img :src="getImgUrlPng(collectibles[rowIdx][colIdx].name)" :alt="collectibles[rowIdx][colIdx].name"
                        class="collectible" />
                    </div>

                    <div class="player-wrapper" :style="{ transform: `scaleX(${playerRotation})` }">
                      <div :class="{ 'move-animation': isMoving }" class="player-tile"
                        v-if="isPlayerTile(rowIdx, colIdx) || isHighlightedTile(rowIdx, colIdx)">
                        <img src="@/assets/player.gif" alt="Player" class="player" />
                        <img src="@/assets/banner_player.png" class="banner">
                      </div>
                    </div>

                    <div class="enemy-wrapper" :style="{ transform: `scaleX(${getEnemyRotation(rowIdx, colIdx)})` }">
                      <Transition name="move">
                        <div class="enemy-tile" v-if="isEnemyTile(rowIdx, colIdx)">
                          <img :src="getImgUrlGif(getEnemyType(rowIdx, colIdx))" :alt="getEnemyType(rowIdx, colIdx)"
                            class="enemy" />
                          <img :src="getBannerImgUrl(getEnemyType(rowIdx, colIdx))" class="banner">
                        </div>
                      </Transition>

                    </div>

                    <b-tooltip v-if="isEnemyTile(rowIdx, colIdx) && isTileVisited(rowIdx, colIdx)"
                      :target="'tooltip-' + getEnemyType(rowIdx, colIdx) + rowIdx + colIdx" placement="top">
                      <div class="enemy-info-name">{{ getEnemy(rowIdx, colIdx).name }}</div>
                      <div class="enemy-info-wrapper">
                        <span class="enemy-info size">{{ getEnemy(rowIdx, colIdx).subEnemies.length }}</span>
                        <span class="enemy-info" :class="{ aggressive: getEnemyAggressive(rowIdx, colIdx) }"></span>
                        <span class="enemy-info" :class="{ elite: getEnemyElite(rowIdx, colIdx) }"></span>
                      </div>
                    </b-tooltip>


                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
        <b-modal centered no-close-on-esc no-close-on-backdrop ref="modal-collectible" hide-header hide-footer>
          <div class="modal-custom-header">{{ foundCollectible.name }}</div>
          <div class="mb-4">
            <div class="text-center mt-4 mb-2 loot">
              <ul>
                <li v-for="(item, index) in foundCollectible.subCollectibles" :key="index">
                  <div v-if="!item.collected" :id="'tooltip-' + item.name + index" tabindex="0">
                    <div :id="item.name" class="loot-item" :class="item.quality">
                      <span class="loot-item-img"><img :src="getImgUrlPng(item.name)" :alt="item.name" /></span>
                    </div>
                    <b-tooltip :target="'tooltip-' + item.name + index" placement="bottom">
                      <div class="inventory-item-name">{{ item.name }}</div>
                      <div class="inventory-item-type"><strong>type:</strong> {{ item.type }}
                      </div>
                      <div class="inventory-item-quantity"><strong>Quality:</strong> <span :class="item.quality">{{
                        item.quality }}</span></div>
                      <button @click="collectCollectible(foundCollectible, item, index)"
                        class="btn btn-success btn-sm">Collect</button>
                    </b-tooltip>
                  </div>
                </li>
              </ul>
            </div>

          </div>

          <div class="modal-custom-close">
            <b-button size="sm" variant="danger" @click="hideModal('collectible')">x</b-button>
          </div>


        </b-modal>

        <div class="interactions" v-if="!modal">

          <div>
            <button v-if="showNextRoundButton" :class="{ 'pulse': gamestate.movement <= 0 }"
              class="btn btn-success btn-block btn-lg" @click="nextRound()">
              <div class="movement"><span v-for="i in gamestate.movement" :key="i" class="movement-points"></span><span
                  v-for="i in gamestate.movementMax - gamestate.movement" :key="i + 'left'"
                  class="movement-points movement-points-left"></span></div> Next Round
            </button>

            <button v-else class="btn btn-secondary btn-disabled btn-block btn-lg" @click="nextRound()"><span
                class="loader"></span> Enemy Movement</button>
          </div>
          <div v-if="foundCollectible.name">
            <button class="btn btn-primary btn-block mt-2" @click="showModal('collectible')">{{
                  foundCollectible.name }}</button>
          </div>
          <div v-if="currentEnemy && showNextRoundButton">
            <button class="btn btn-danger btn-block mt-2" @click="startBattle()">{{ currentEnemy.type.name }}</button>
          </div>




        </div>

        <div class="ui-box-wrapper top-right text-left d-none">
          <div class="ui-box-wrapper-head">Quests</div>
          <ul class="p-0 m-0 list-unstyled quest-list">
            <li class="mb-1" v-for=" quest  in  quests " :key="quest.id">
              {{ quest.type }} <span v-if="!quest.completed">{{ quest.progress }}/{{ quest.targetAmount }}</span> {{
                quest.target }} <span v-if="quest.collectibleName">({{ quest.collectibleName }})</span>
              <span class="plus" v-if="quest.completed"> (Done)</span>
            </li>
          </ul>
        </div>
      </div>

      <div v-else class="text-center">
        <div class="explored">{{ visitedPercentage }}% Visited</div>
        <div class="location font-size-l text-center mb-3">{{ gamestate.location }}</div>
        <div class="planet-map"
          :style="{ 'grid-template-columns': 'repeat(' + mapSize + ', 8px)', 'grid-template-rows': 'repeat(' + mapSize + ', 8px)' }">
          <div v-for="( row, rowIdx ) in  map " :key="rowIdx" class="map-row">
            <div v-for="( tile, colIdx ) in  row " :key="colIdx" :class="[
              'map-tile',
              tile.biome,
              { 'visited': isTileVisited(rowIdx, colIdx) }
            ]
              ">

              <div v-if="isPlayerTile(rowIdx, colIdx)">
                <span class="minimap-player"></span>
              </div>

              <div v-if="foundCollectible && foundCollectible.collected == false">
                <img :src="getImgUrlPng(collectibles[rowIdx][colIdx].name)" :alt="collectibles[rowIdx][colIdx].name"
                  class="minimap-collectible" />
              </div>

              <div v-if="isEnemyTile(rowIdx, colIdx)">
                <img :src="getImgUrlGif(getEnemyType(rowIdx, colIdx))" :alt="getEnemyType(rowIdx, colIdx)"
                  class="minimap-enemy" />
              </div>


            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { Noise } from 'noisejs';
import Battle from "./Battle.vue";
import lootTables from '@/loot/lootTable.json';
import enemyTables from '@/loot/enemyTable.json';
export default {
  props: {
    minimap: Boolean,
    gamestate: Object,
    modal: Boolean,
  },
  components: {
    Battle,
  },
  data() {
    return {
      map: [],
      collectibles: [],
      quests: [],
      lootTables: lootTables,
      showBattle: false,
      showNextRoundButton: true,
      activeTile: null,
      // mapSize: Math.floor(Math.random() * 25) + 10,
      mapSize: 25,
      mapWidth: null,
      mapHeight: null,
      foundCollectible: {},
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      playerRotation: 1,
      enemies: [],
      currentEnemy: null,
      timer: null,
      isMoving: false,
      player: {
        row: -1,
        col: -1
      },
      visitedRange: 1,
      visitedTiles: [],
      enemyTypes: enemyTables,
    }
  },
  created() {
    window.addEventListener('resize', this.handleWindowResize);
    this.generateMapIfLocalStorageEmpty();
    //window.addEventListener('keydown', this.handleKeyDown);
  },
  watch: {
    windowSize(newWidth, newHeight) {
      this.mapContainerStyle = this.calculateMapContainerStyle(newWidth, newHeight);
    },
    'player.row'(newRow) {
      this.checkPlayerOnEnemyTile(newRow, this.player.col);
    },
    'player.col'(newCol) {
      this.checkPlayerOnEnemyTile(this.player.row, newCol);
    },

  },
  mounted() {
    if (!this.minimap) {
      if (this.gamestate.showBattle) {
        this.startBattle();
      }
    }
    const savedPlayer = localStorage.getItem('player');
    const visitedTiles = localStorage.getItem('visitedTiles');
    const foundCollectible = localStorage.getItem('foundCollectible');

    if (savedPlayer) {
      this.player = JSON.parse(savedPlayer);
    } else {
      do {
        // Generate random row and column indices
        const row = Math.floor(Math.random() * this.mapHeight);
        const col = Math.floor(Math.random() * this.mapWidth);

        // Check if the tile at the randomly generated position is moveable
        if (this.isTileMoveable(row, col)) {
          // Spawn player at the moveable tile
          this.player = { row, col };

          // Mark tile and adjacent tiles as visited
          this.markTileAsVisited(row, col);
          this.markAdjacentTilesAsVisited(row, col);
        }
      } while (this.checkPlayerOnEnemyTile(this.player.row, this.player.col));

      localStorage.setItem('player', JSON.stringify(this.player));
      localStorage.setItem('visitedTiles', JSON.stringify(this.visitedTiles));
    }

    if (visitedTiles) {
      this.visitedTiles = JSON.parse(visitedTiles);
    }

    if (foundCollectible) {
      this.foundCollectible = JSON.parse(foundCollectible);
      if (this.foundCollectible && this.foundCollectible.collected == false) {
        //this.showModal('collectible');
      }

    } else {
      this.foundCollectible = {};
    }


    const storedQuests = localStorage.getItem('quests');
    if (storedQuests) {
      this.quests = JSON.parse(storedQuests);
    } else {
      this.initializeQuests();
    }
  },
  computed: {
    mapContainerStyle() {
      return this.calculateMapContainerStyle(this.windowWidth, this.windowHeight);
    },
    visitedPercentage() {
      if (this.visitedTiles.length === 0) {
        return 0;
      }

      const totalTiles = this.mapSize * this.mapSize;
      const visitedTiles = this.visitedTiles.length;

      return Math.round((visitedTiles / totalTiles) * 100);
    },
    currentBiome() {
      if (this.player.row >= 0 && this.player.col >= 0) {
        return this.map[this.player.row][this.player.col].biome;
      }
      return null; // Default or null if player position is not set
    },

  },
  methods: {
    toggleFullscreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        document.body.requestFullscreen().catch(err => {
          console.error(`Error attempting to enable full-screen mode: ${err.message}`);
        });
      }
    },
    handleTileClick(rowIdx, colIdx) {
      // Only make highlighted tiles active
      if (this.isHighlightedTile(rowIdx, colIdx)) {
        if (this.activeTile === `${rowIdx}-${colIdx}`) {
          this.movePlayer(rowIdx, colIdx);
          this.activeTile = null; // Reset active tile after moving player
        } else {
          // Set this tile as active
          this.activeTile = `${rowIdx}-${colIdx}`;
        }
      }
    },
    moveAllEnemiesWithDelay() {
      this.moveAllEnemies();
      if (this.iteration < 2) {
        this.iteration++;
        setTimeout(this.moveAllEnemiesWithDelay, 1000);
      } else {
        this.editGameState('addMovement', this.gamestate.movementMax);
        this.showNextRoundButton = true;
      }
    },
    nextRound() {
      this.iteration = 0;
      this.showNextRoundButton = false;
      setTimeout(this.moveAllEnemiesWithDelay, 1000);
    },
    startBattle() {
      this.showBattle = true;
      this.editGameState('showBattle', true);
    },
    checkPlayerOnEnemyTile(rowIdx, colIdx) {
      if (this.isEnemyTile(rowIdx, colIdx)) {
        this.handlePlayerOnEnemyTile(rowIdx, colIdx);
      } else {
        this.currentEnemy = null;
      }
    },
    handlePlayerOnEnemyTile(rowIdx, colIdx) {
      const enemy = this.enemies.find((enemy) => enemy.row === rowIdx && enemy.col === colIdx);
      if (enemy) {
        this.currentEnemy = enemy;
        if (this.currentEnemy && this.currentEnemy.type.aggressive) {
          this.startBattle();
        }
      }
    },
    checkEnemyTile(rowIdx, colIdx) {
      if (this.isEnemyTile(rowIdx, colIdx)) {
        this.currentEnemy = this.enemies.find((enemy) => enemy.row === rowIdx && enemy.col === colIdx);
        if (this.currentEnemy && this.currentEnemy.type.aggressive) {
          this.startBattle();
        }
      } else {
        this.currentEnemy = null;
      }
    },
    calculateMapContainerStyle(windowWidth, windowHeight) {
      const mapTileSize = 64;
      const gap = 4;
      const screenWidth = windowWidth;
      const screenHeight = windowHeight;
      const mapWidth = this.mapWidth * (mapTileSize + gap) - gap;
      const mapHeight = this.mapHeight * (mapTileSize + gap) - gap;
      const playerPositionX = this.player.col * (mapTileSize + gap);
      const playerPositionY = this.player.row * (mapTileSize + gap);

      const offsetX = (screenWidth / 2) - playerPositionX - (mapTileSize / 2);
      const offsetY = (screenHeight / 2) - playerPositionY - (mapTileSize / 2);

      return {
        transform: `translate(${offsetX}px, ${offsetY}px)`,
        width: mapWidth + 'px',
        height: mapHeight + 'px',
      };
    },
    handleWindowResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    getImgUrlPng(name) {
      var images = require.context('../assets/', false, /\.png$/)
      return images('./' + name + ".png")
    },
    getImgUrlGif(name) {
      var images = require.context('../assets/', false, /\.gif$/)
      return images('./' + name + ".gif")
    },
    getBannerImgUrl(name) {
      var images = require.context('../assets/', false, /\.png$/)
      return images('./' + 'banner_' + name + ".png")
    },
    initializeQuests() {
      this.quests = [
        {
          id: 1,
          description: 'Collect 3 ores',
          type: 'collect',
          target: 'ores',
          collectibleName: '',
          targetAmount: 10,
          progress: 0,
          completed: false
        },
        {
          id: 2,
          description: 'Collect 5 copper',
          type: 'collect',
          target: 'ores',
          collectibleName: 'copper',
          targetAmount: 5,
          progress: 0,
          completed: false
        },
      ];
    },
    checkQuests() {
      this.quests.forEach(quest => {
        if (!quest.completed) {
          if (quest.type === 'collect') {
            const collectedItems = this.collectibles.flat().filter(collectible => {
              return (
                collectible &&
                collectible.type === quest.target &&
                collectible.collected &&
                (quest.collectibleName === '' || quest.collectibleName === collectible.name) // Check collectible name
              );
            });
            const totalCollected = collectedItems.reduce((total, item) => total + item.nmb, 0);
            quest.progress = totalCollected;

            if (quest.progress >= quest.targetAmount) {
              quest.completed = true;
            }
          }
        }
      });

      localStorage.setItem('quests', JSON.stringify(this.quests));
    },
    addToInventory(value) {
      this.$emit('addToInventory', value)
    },
    removeFromInventory(value) {
      this.$emit('removeFromInventory', value)
    },
    editGameState(value, change) {
      this.$emit('editGameState', value, change)
    },
    showModal(type) {
      this.$refs['modal-' + type].show(),
        this.$parent.$data.isModalOpen = true;
    },
    hideModal(type) {
      this.$refs['modal-' + type].hide(),
        this.$parent.$data.isModalOpen = false;
    },
    hasCollectible(rowIdx, colIdx) {

      const collectible = this.collectibles[rowIdx][colIdx];

      if (collectible && !collectible.collected) {
        return true;
      }

      return false;
    },
    selectEnemyTypeBasedOnChance() {
      // Generate a random number between 0 (inclusive) and 1 (exclusive)
      const randomNumber = Math.random();

      // Initialize variables to store the cumulative probability
      let cumulativeProbability = 0;

      // Iterate through the enemyTypes array
      for (const enemyType of this.enemyTypes) {
        // Add the chance of the current enemy type to the cumulative probability
        cumulativeProbability += enemyType.chance;

        // Check if the random number is less than the cumulative probability
        // If it is, select this enemy type
        if (randomNumber < cumulativeProbability) {
          // Return the selected enemy type
          return enemyType;

        }
      }

      // If no enemy type is selected, return null or handle it appropriately
      return null;
    },
    generateMap() {
      this.mapWidth = this.mapSize;
      this.mapHeight = this.mapSize;
      const { mapWidth, mapHeight } = this;
      const noise = new Noise(Math.random());

      const biomes = ['level-0', 'level-1', 'level-2', 'level-3', 'level-4', 'level-5', 'level-6', 'level-7'];

      const map = [];
      const collectibles = [];
      const enemies = [];

      function getRandomQuality(qualities, probabilities) {
        // Validate input arrays
        if (qualities.length !== probabilities.length) {
          throw new Error('Qualities and probabilities arrays must have the same length');
        }

        // Calculate total probability
        const totalProbability = probabilities.reduce((acc, prob) => acc + prob, 0);

        // Generate a random value within the total probability range
        const randomValue = Math.random() * totalProbability;

        // Determine the quality based on manual probabilities
        let cumulativeProbability = 0;
        for (let i = 0; i < probabilities.length; i++) {
          cumulativeProbability += probabilities[i];
          if (randomValue <= cumulativeProbability) {
            return qualities[i];
          }
        }

        // Fallback to the last quality if something goes wrong
        return qualities[qualities.length - 1];
      }

      const qualityProbabilities = [60, 20, 10, 3.5, 1, 0.5];

      for (let y = 0; y < mapHeight; y++) {
        const row = [];
        const collectibleRow = [];
        for (let x = 0; x < mapWidth; x++) {
          const noiseValue = noise.simplex2(x / 8, y / 8);
          const biomeIndex = Math.floor(noiseValue * biomes.length);
          const biome = biomes[biomeIndex];

          if (!biome) {
            row.push({ biome: 'level-1' });
          } else {
            row.push({ biome });
          }

          if (!biome && Math.random() < 0.6) {
            row[row.length - 1].biome = 'level-2';
          }

          if (!biome && Math.random() < 0.3) {
            row[row.length - 1].biome = 'level-3';
          }


          if (Math.random() < 0.1 && !this.isPlayerTile(y, x)) {
            const biome = row[row.length - 1].biome; // Get the biome of the current tile
            if (biome !== 'level-0') { // Check if the biome is not level-0
              // Select an enemy type based on their chances
              const selectedEnemyType = this.selectEnemyTypeBasedOnChance();

              // If an enemy type is selected
              if (selectedEnemyType) {
                // Generate a random number to determine if the enemy should be flipped horizontally
                const flipHorizontally = Math.random() < 0.5;

                // Push the final enemy object into the enemies array
                enemies.push({
                  row: y,
                  col: x,
                  type: selectedEnemyType,
                  rotation: flipHorizontally ? -1 : 1,
                  // You can include other properties of the enemy object here
                });
              }
            }
          }

          let collectible = null;

          const qualities = ['poor', 'common', 'uncommon', 'rare', 'epic', 'legendary'];

          const lootTable = lootTables[biome];

          if (lootTable) {
            const lootKeys = Object.keys(lootTable);
            const eligibleLootKeys = lootKeys.filter(key => Math.random() < lootTable[key].chance);
            if (eligibleLootKeys.length > 0) {
              const randomLootKey = eligibleLootKeys[Math.floor(Math.random() * eligibleLootKeys.length)];
              const lootEntry = lootTable[randomLootKey];

              const subCollectibles = lootEntry.subCollectibles.map(sub => {
                if (Math.random() < sub.chance) {
                  return {
                    name: sub.name,
                    type: sub.type,
                    chance: sub.chance,
                    quality: getRandomQuality(qualities, qualityProbabilities),
                    collected: false,
                  };
                } else {
                  return null; // Do not include this sub-collectible
                }
              }).filter(sub => sub !== null);

              collectible = {
                name: randomLootKey,
                collected: false,
                subCollectibles: subCollectibles,
              };
            }
          }

          collectibleRow.push(collectible);
        }

        map.push(row);
        collectibles.push(collectibleRow);
      }

      this.map = map;
      this.collectibles = collectibles;
      this.enemies = enemies;
    },

    isPlayerTile(rowIdx, colIdx) {
      return this.player.row === rowIdx && this.player.col === colIdx;
    },
    isTileMoveable(rowIdx, colIdx) {
      // Check if rowIdx and colIdx are within the bounds of the map
      if (
        rowIdx >= 0 && rowIdx < this.map.length &&
        colIdx >= 0 && colIdx < this.map[rowIdx].length
      ) {
        const tile = this.map[rowIdx][colIdx];
        // Check if tile exists and its biome is not 'level-0'
        return tile && tile.biome !== 'level-0';
      }
      // Return false if rowIdx or colIdx is out of bounds
      return false;
    },
    isEnemyTile(rowIdx, colIdx) {
      return this.enemies.some(
        (enemy) => enemy.row === rowIdx && enemy.col === colIdx
      );
    },
    getEnemy(rowIdx, colIdx) {
      const enemy = this.enemies.find(
        (enemy) => enemy.row === rowIdx && enemy.col === colIdx
      );
      return enemy ? enemy.type : '';
    },
    getEnemyType(rowIdx, colIdx) {
      const enemy = this.enemies.find(
        (enemy) => enemy.row === rowIdx && enemy.col === colIdx
      );
      return enemy ? enemy.type.type : '';
    },
    getEnemyAggressive(rowIdx, colIdx) {
      const enemy = this.enemies.find(
        (enemy) => enemy.row === rowIdx && enemy.col === colIdx
      );
      return enemy ? enemy.type.aggressive : '';
    },
    getEnemyElite(rowIdx, colIdx) {
      const enemy = this.enemies.find(
        (enemy) => enemy.row === rowIdx && enemy.col === colIdx
      );
      return enemy ? enemy.type.elite : '';
    },
    getEnemyRotation(rowIdx, colIdx) {
      const enemy = this.enemies.find(
        (enemy) => enemy.row === rowIdx && enemy.col === colIdx
      );
      return enemy ? enemy.rotation : '';
    },
    getEnemyImage(rowIdx, colIdx) {
      const enemy = this.enemies.find(
        (enemy) => enemy.row === rowIdx && enemy.col === colIdx
      );
      return enemy ? enemy.type.image : '';
    },
    movePlayer(newRow, newCol) {
      if (!this.modal && this.gamestate.movement > 0) {
        this.isMoving = true;
        if (newCol < this.player.col) {
          this.playerRotation = -1;
        } else if (newCol > this.player.col) {
          this.playerRotation = 1;
        }

        if (this.isTileMoveable(newRow, newCol)) {
          if (this.isHighlightedTile(newRow, newCol)) {
            if (this.hasCollectible(newRow, newCol)) {
              this.foundCollectible = this.collectibles[newRow][newCol];
              this.foundCollectible.newRow = newRow;
              this.foundCollectible.newCol = newCol;

              if (this.foundCollectible && this.foundCollectible.collected == false) {
                //this.showModal('collectible');
              }


            } else {
              this.foundCollectible = {};
            }
            this.setPlayerPosition(newRow, newCol);
            this.markAdjacentTilesAsVisited(newRow, newCol);
            this.markTileAsVisited(newRow, newCol);
            this.editGameState('substractMovement', 1);
            this.checkEnemyTile(newRow, newCol);
          }
          this.saveFoundCollectible();
          setTimeout(() => {
            this.isMoving = false;
          }, 500);
        }
      }

    },
    moveAllEnemies() {
      if (this.modal) {
        return;
      }
      this.$root.$emit('bv::hide::tooltip');

      this.enemies.forEach((enemy) => {
        // Consider all 8 possible movements (N, NE, E, SE, S, SW, W, NW)
        const movements = [
          { row: -1, col: 0 }, // N
          { row: -1, col: 1 }, // NE
          { row: 0, col: 1 },  // E
          { row: 1, col: 1 },  // SE
          { row: 1, col: 0 },  // S
          { row: 1, col: -1 }, // SW
          { row: 0, col: -1 }, // W
          { row: -1, col: -1 } // NW
        ];

        const chaseRange = 2; // Or whatever your chase range is
        const distanceToPlayer = Math.abs(this.player.row - enemy.row) + Math.abs(this.player.col - enemy.col);

        if (distanceToPlayer <= chaseRange && enemy.type.aggressive) {
          // Implement chasing logic that includes diagonal movement towards the player
          const dirIndex = this.determineDirectionForChase(enemy);
          const movement = movements[dirIndex];
          this.attemptEnemyMovement(enemy, movement);
        } else {
          // Random movement logic, potentially including diagonal movements
          const randomIndex = Math.floor(Math.random() * movements.length);
          const movement = movements[randomIndex];
          this.attemptEnemyMovement(enemy, movement);
        }
      });

      this.saveEnemiesToLocalStorage();
      this.checkPlayerOnEnemyTile(this.player.row, this.player.col);
    },

    determineDirectionForChase(enemy) {
      const rowDiff = this.player.row - enemy.row;
      const colDiff = this.player.col - enemy.col;

      // Normalize the differences to get direction (-1, 0, or 1)
      const normalizedRowDiff = rowDiff === 0 ? 0 : rowDiff / Math.abs(rowDiff);
      const normalizedColDiff = colDiff === 0 ? 0 : colDiff / Math.abs(colDiff);

      // Map the normalized differences to the movements array indices
      // N, NE, E, SE, S, SW, W, NW correspond to indices 0, 1, 2, 3, 4, 5, 6, 7 respectively
      if (normalizedRowDiff === -1 && normalizedColDiff === 0) return 0; // N
      if (normalizedRowDiff === -1 && normalizedColDiff === 1) return 1; // NE
      if (normalizedRowDiff === 0 && normalizedColDiff === 1) return 2; // E
      if (normalizedRowDiff === 1 && normalizedColDiff === 1) return 3; // SE
      if (normalizedRowDiff === 1 && normalizedColDiff === 0) return 4; // S
      if (normalizedRowDiff === 1 && normalizedColDiff === -1) return 5; // SW
      if (normalizedRowDiff === 0 && normalizedColDiff === -1) return 6; // W
      if (normalizedRowDiff === -1 && normalizedColDiff === -1) return 7; // NW

      // Fallback to a default direction if needed, though all cases should be covered above
      return 0; // Example fallback to North
    },

    attemptEnemyMovement(enemy, movement) {
      const newRow = enemy.row + movement.row;
      const newCol = enemy.col + movement.col;

      // Check if the new position is within bounds and moveable
      if (this.isTileMoveable(newRow, newCol) && !this.isOtherEnemyThere(newRow, newCol, enemy)) {
        enemy.row = newRow;
        enemy.col = newCol;
      }
    },

    isOtherEnemyThere(newRow, newCol, currentEnemy) {
      return this.enemies.some(enemy => enemy !== currentEnemy && enemy.row === newRow && enemy.col === newCol);
    },

    saveEnemiesToLocalStorage() {
      localStorage.setItem('enemies', JSON.stringify(this.enemies));
    },
    collectCollectible(foundCollectible, item, index) {
      if (this.isInventoryFull()) {
        this.$bvToast.toast('Inventory is full!', {
          toaster: 'b-toaster-top-center',
          solid: true,
          variant: 'danger'
        });
      } else {
        console.log(foundCollectible, item)
        console.log('Index' + index);
        this.addToInventory(item);
        this.collectibles[foundCollectible.newRow][foundCollectible.newCol].subCollectibles[index].collected = true;

        // Check if all subitems are collected
        const allSubitemsCollected = this.collectibles[foundCollectible.newRow][foundCollectible.newCol].subCollectibles.every(subItem => subItem.collected);

        // If all subitems are collected, set the main item as collected
        if (allSubitemsCollected) {
          this.collectibles[foundCollectible.newRow][foundCollectible.newCol].collected = true;
          this.hideModal('collectible');
          this.foundCollectible = {};
        }

        this.saveFoundCollectible();
        this.saveMapToLocalStorage();
        this.checkQuests();
        //this.collectibles[foundCollectible.newRow][foundCollectible.newCol].collected = true;
        // this.hideModal('collectible');
        // this.collectibles[rowIdx][colIdx].collected = true;
        // console.log("Collected: " + this.collectibles[rowIdx][colIdx].nmb + ' ' + this.collectibles[rowIdx][colIdx].name)
        // this.addToInventory(this.collectibles[rowIdx][colIdx]);
        // this.foundCollectible = {};
        // this.saveFoundCollectible();
        // this.saveMapToLocalStorage();
        // this.checkQuests();
      }
    },
    isInventoryFull() {
      return this.gamestate.inventory >= this.gamestate.inventoryMax;
    },
    markTileAsVisited(rowIdx, colIdx) {
      if (!this.isTileVisited(rowIdx, colIdx)) {
        this.visitedTiles.push({ row: rowIdx, col: colIdx });
        this.saveVisitedTilesToLocalStorage();
      }
    },
    markAdjacentTilesAsVisited(rowIdx, colIdx) {
      const { visitedRange } = this;

      for (let i = rowIdx - visitedRange; i <= rowIdx + visitedRange; i++) {
        for (let j = colIdx - visitedRange; j <= colIdx + visitedRange; j++) {
          if (this.isTileWithinMapBounds(i, j) && !this.isTileVisited(i, j)) {
            this.markTileAsVisited(i, j);
          }
        }
      }
    },
    isTileWithinMapBounds(row, col) {
      return row >= 0 && row < this.mapSize && col >= 0 && col < this.mapSize;
    },
    isHighlightedTile(rowIdx, colIdx) {
      const currentRow = this.player.row;
      const currentCol = this.player.col;

      // Allow diagonal movement by checking if the tile is within a 1-tile radius
      const isDiagonalOrAdjacent = Math.abs(rowIdx - currentRow) <= 1 && Math.abs(colIdx - currentCol) <= 1;

      return isDiagonalOrAdjacent && !(rowIdx === currentRow && colIdx === currentCol); // Exclude the current tile
    },
    saveVisitedTilesToLocalStorage() {
      localStorage.setItem('visitedTiles', JSON.stringify(this.visitedTiles));
    },
    saveFoundCollectible() {
      localStorage.setItem('foundCollectible', JSON.stringify(this.foundCollectible));
    },
    isTileVisited(rowIdx, colIdx) {
      return this.visitedTiles.some(
        (visitedTile) => visitedTile.row === rowIdx && visitedTile.col === colIdx
      );
    },
    isAdjacentTile(currentRow, currentCol, newRow, newCol) {
      const rowDiff = Math.abs(newRow - currentRow);
      const colDiff = Math.abs(newCol - currentCol);
      return rowDiff <= 1 && colDiff <= 1 && (rowDiff !== 0 || colDiff !== 0);
    },
    setPlayerPosition(rowIdx, colIdx) {
      this.player.row = rowIdx;
      this.player.col = colIdx;
      localStorage.setItem('player', JSON.stringify(this.player));
    },
    generateMapIfLocalStorageEmpty() {
      if (!localStorage.getItem('map') || !localStorage.getItem('collectibles')) {
        this.$parent.saveData();
        this.generateMap();
        this.saveMapToLocalStorage();
      } else {
        this.loadMapFromLocalStorage();
      }
    },
    saveMapToLocalStorage() {
      const mapJson = JSON.stringify(this.map);
      const enemiesJson = JSON.stringify(this.enemies);
      const collectiblesJson = JSON.stringify(this.collectibles);
      const mapSize = JSON.stringify(this.mapSize);

      localStorage.setItem('map', mapJson);
      localStorage.setItem('enemies', enemiesJson);
      localStorage.setItem('collectibles', collectiblesJson);
      localStorage.setItem('mapSize', mapSize);
    },
    loadMapFromLocalStorage() {
      const mapJson = localStorage.getItem('map');
      const enemiesJson = localStorage.getItem('enemies');
      const collectiblesJson = localStorage.getItem('collectibles');
      const mapSize = localStorage.getItem('mapSize');

      if (mapJson && collectiblesJson && mapSize) {
        this.map = JSON.parse(mapJson);
        this.enemies = JSON.parse(enemiesJson);
        this.collectibles = JSON.parse(collectiblesJson);
        this.mapSize = mapSize;
      }
    },
  },
};
</script>