<template>
    <div id="app" :class="{ 'day': isDay, 'night': !isDay, 'paused': pause }">
        <div v-if="!isDay" class="rain">
            <div v-for="drop in raindrops" :key="drop.id" class="raindrop"
                :style="{ left: drop.left + 'px', animationDuration: drop.animationDuration + 's' }"></div>
        </div>
        <div class="lightning" v-if="showLightning && !isDay"></div>
        <div v-if="pause" class="start-wrapper">
            <div class="start ui-box-wrapper" style="max-width: 350px">
                <div class="mb-3">
                    <div class="player-wrapper d-inline-block">
                        <img src="@/assets/player.gif" width="128px"/>
                        <img src="@/assets/banner_player.png" width="128px" style="position: absolute;
                        left: 36px;" class="banner">
                    </div>
                </div>
                <div>
                    <button class="btn btn-lg btn-primary mb-3 w-100" @click="startGame()">Start</button>
                    <button class="btn btn-lg btn-warning mb-3 w-100" @click="restartGame()">Restart</button>
                    <button class="btn btn-lg btn-success w-100" @click="toggleFullscreen">Toggle
                        Fullscreen</button>
                </div>
            </div>
        </div>
        <div v-else>
            <Map :minimap="false" :gamestate="gamestate" :modal="isModalOpen" @addToInventory="addToInventory"
                @removeFromInventory="removeFromInventory" @editGameState="editGameState" />

            <div v-if="!gamestate.showBattle" class="bottom-center">
                <div>
                    <div class="ui-box" @click="!isModalOpen ? showModal('gear') : {}">
                        <img src="@/assets/gear.png" alt="gear" />
                        <div class="ui-box-name">gear</div>
                    </div>
                    <div class="ui-box" @click="!isModalOpen ? showModal('inventory') : {}">
                        <img src="@/assets/bag.png" alt="Inventory" />
                        <div class="ui-box-name">Bag</div>
                    </div>
                    <div class="ui-box" @click="!isModalOpen ? showModal('minimap') : {}">
                        <img src="@/assets/map.png" alt="Map" />
                        <div class="ui-box-name">Map</div>
                    </div>

                </div>
            </div>

            <b-modal no-close-on-esc no-close-on-backdrop centered ref="modal-inventory" hide-header hide-footer>
                <div class="modal-custom-header">Bag {{ playerInventory.length }}/{{ gamestate.inventoryMax }}</div>
                <Inventory :gamestate="gamestate" :inventory="playerInventory" @editGameState="editGameState"
                    @removeFromInventory="removeFromInventory" />
                <div class="modal-custom-close">
                    <b-button class="btn-sm" variant="danger" @click="hideModal('inventory')">x</b-button>
                </div>

            </b-modal>
            <b-modal no-close-on-esc no-close-on-backdrop centered ref="modal-minimap" hide-header hide-footer>
                <div class="modal-custom-header">Map</div>
                <Map :gamestate="gamestate" :modal="isModalOpen" :minimap="true" class="minimap d-block" />
                <div class="modal-custom-close">
                    <b-button class="btn-sm" variant="danger" @click="hideModal('minimap')">x</b-button>
                </div>
            </b-modal>

            <b-modal centered ref="modal-message" hide-footer :title="messageTitle">
                <p>{{ messageDesc }}</p>
                <b-button v-if="messageTitle == 'Game Over!'" class="mt-3" variant="danger" block
                    @click="hideMessage(); restartGame()">x Restart</b-button>
                <b-button v-else class="mt-3" variant="danger" block @click="hideMessage()">x</b-button>
            </b-modal>
        </div>
    </div>
</template>
  
<script>
import Map from './components/Map.vue';
import Inventory from "./components/Inventory.vue";

export default {
    data() {
        return {
            show: false,
            gameRunning: false,
            loading: false,
            messageTitle: '',
            messageDesc: '',
            showLightning: false,
            raindrops: [],
            numRaindrops: 50,
            playerInventory: [
                { name: 'healthpot', type: 'consumable', quality: 'poor' },
            ],
            progressValue: 0,
            isDay: true,
            currentTime: this.getCurrentTime(),
            gamestate: {
                playerName: 'Koga Shuku',
                playerHealth: 25,
                playerMaxHealth: 25,
                playerStrength: 7,
                playerAgility: 5,
                playerDmg: 5,
                playerDefense: 5,
                abilityPoints: 0,
                location: 'Forest Hills',
                inventory: 0,
                inventoryMax: 15,
                cycle: 'night',
                showBattle: false,
                movement: 5,
                movementMax: 5,
                weapon: {
                    name: 'basic bow', type: 'range', range: 4, image: 'bow', damage: 5, active: true,
                },
            },
            pause: true,
            isModalOpen: false,
        };
    },
    components: {
        Map,
        Inventory,
    },
    created() {
        // Load game data from local storage
        const savedPlayerInventory = JSON.parse(localStorage.getItem("playerInventory"));
        const savedGamestate = JSON.parse(localStorage.getItem("gamestate"));

        if (savedPlayerInventory) {
            this.playerInventory = savedPlayerInventory;
        }
        if (savedGamestate) {
            this.gamestate = savedGamestate
        }
        this.gamestate.inventory = this.playerInventory.length;
    },
    mounted() {
        this.createRaindrops();
        this.toggleLightning();
        this.handleResize();

        // Add a resize event listener
        window.addEventListener('resize', this.handleResize);

    },
    beforeDestroy() {
        // Remove the resize event listener to avoid memory leaks
        window.removeEventListener('resize', this.handleResize);
    },
    computed: {
        isDocumentHidden() {
            return document.hidden;
        }
    },
    methods: {
        toggleFullscreen() {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            } else {
                document.body.requestFullscreen().catch(err => {
                    console.error(`Error attempting to enable full-screen mode: ${err.message}`);
                });
            }
        },
        createRaindrops() {
            this.raindrops = [];
            for (let i = 0; i < this.numRaindrops; i++) {
                this.raindrops.push({
                    id: i,
                    left: Math.random() * window.innerWidth,
                    top: Math.random() * window.innerHeight,
                    animationDuration: Math.random() * 2 + 1,
                });
            }
        },
        handleResize() {
            this.raindrops.forEach(raindrop => {
                raindrop.left = Math.random() * window.innerWidth;
                raindrop.top = Math.random() * window.innerHeight;
            });
        },
        toggleLightning() {
            this.showLightning = Math.random() < 0.2; // Adjust probability as needed
            const delay = this.getRandomInt(500, 3000); // Set a delay between 0.5s and 5s
            setTimeout(this.toggleLightning, delay);
        },
        getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        getCurrentTime() {
            const now = new Date();
            const hours = now.getHours();
            const minutes = now.getMinutes();
            const formattedTime = `${this.formatTime(hours)}:${this.formatTime(minutes)}`;
            return formattedTime;
        },
        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },
        startGame() {
            if (!this.isDocumentHidden) {
                this.pause = false;
            }
        },
        updateInventoryCount() {
            this.gamestate.inventory = this.playerInventory.length;
        },
        addToInventory(value) {
            this.$bvToast.toast(`Collected ${value.name}`, {
                toaster: 'b-toaster-top-center',
                solid: true,
                variant: 'success'
            });
            this.playerInventory.push(value);
            this.updateInventoryCount();
            this.saveData();
        },
        removeFromInventory(value) {
            // Check if the item exists in the inventory
            const indexToRemove = this.playerInventory.findIndex(item => item === value);

            if (indexToRemove !== -1) {
                // Remove the item from the array
                this.playerInventory.splice(indexToRemove, 1);

                // Display a toast notification for item removal
                this.$bvToast.toast(`Removed ${value.name} from inventory`, {
                    toaster: 'b-toaster-top-center',
                    solid: true,
                    variant: 'danger'
                });

                // Update the inventory count
                this.updateInventoryCount();

                // Save the updated data
                this.saveData();
            } else {
                // If the item is not found in the inventory, display an error toast
                this.$bvToast.toast(`Error: ${value.name} not found in inventory`, {
                    toaster: 'b-toaster-top-center',
                    solid: true,
                    variant: 'warning'
                });
            }
        },

        editGameState(value, change) {
            if (value == 'movement') {
                this.gamestate.movementPoints -= change;
            }
            if (value == 'showBattle') {
                this.gamestate.showBattle = change;
            }
            if (value.use == 'addenergy') {
                if (this.gamestate.playerEnergy < 100) {
                    this.gamestate.playerEnergy += value.add;
                    if (this.gamestate.playerEnergy > 100) {
                        this.gamestate.playerEnergy = 100
                    }
                    this.$bvToast.toast(`your energy is now at ${this.gamestate.playerEnergy}`, {
                        toaster: 'b-toaster-top-center',
                        solid: true,
                        variant: 'info'
                    })
                } else {
                    this.$bvToast.toast(`your energy is full`, {
                        toaster: 'b-toaster-top-center',
                        solid: true,
                        variant: 'success'
                    })
                }
            }
            if (value.use == 'addhealth') {
                if (this.gamestate.playerHealth < 100) {
                    this.gamestate.playerHealth += value.add;
                    if (this.gamestate.playerHealth > 100) {
                        this.gamestate.playerHealth = 100
                    }
                    this.$bvToast.toast(`your health is now at ${this.gamestate.playerHealth}`, {
                        toaster: 'b-toaster-top-center',
                        solid: true,
                        variant: 'info'
                    })
                } else {
                    this.$bvToast.toast(`you are completely healed`, {
                        toaster: 'b-toaster-top-center',
                        solid: true,
                        variant: 'success'
                    })
                }
            }
            if (value == 'substractEnergy') {
                this.gamestate.playerEnergy -= change;
                if (this.gamestate.playerEnergy < 0) {
                    this.gamestate.playerEnergy = 0
                }
            }
            if (value == 'substractMovement') {
                this.gamestate.movement -= change;
            }
            if (value == 'addMovement') {
                this.gamestate.movement = change;
            }
            if (value == 'substractHealth') {
                this.gamestate.playerHealth -= change;
            }

            this.saveData();
        },
        showMessage() {
            this.$refs['modal-message'].show()
        },
        hideMessage() {
            this.$refs['modal-message'].hide()
        },
        showModal(type) {
            this.$refs['modal-' + type].show()
            this.isModalOpen = true;
        },
        hideModal(type) {
            this.$refs['modal-' + type].hide()
            this.isModalOpen = false;
        },
        onShown() {
            this.$refs.cancel.focus()
        },
        onHidden() {
            this.$refs.show.focus()
        },

        gameOver() {
            this.gameRunning = false;
            this.messageTitle = 'Game Over!'
            this.messageDesc = 'In the face of these insurmountable challenges, the game has come to an end. The fatal conditions have rendered the planet inhospitable and unsustainable, marking a somber conclusion to the colonys struggle for survival. It serves as a reminder of the delicate balance required for successful management in such a challenging environment.'
            this.showMessage();
        },
        restartGame() {
            localStorage.clear();
            location.reload();
        },
        saveData() {
            localStorage.setItem("playerInventory", JSON.stringify(this.playerInventory));
            localStorage.setItem("gamestate", JSON.stringify(this.gamestate));
        },
    },
};
</script>
