<template>
    <div>

        <div class="size-100" :class="{ 'no-movement': abilityPoints == 0 }">

            <div class="map">
                <div>
                    <div class="interactions" v-if="!modal">
                        <button v-if="!roundEnded" :class="{ 'pulse': abilityPoints == 0 }"
                            class="btn btn-success btn-block btn-lg" @click="endRound()">
                            <div class="movement"><span v-for="i in abilityPoints" :key="i"
                                    class="movement-points"></span><span v-for="i in abilityPointsMax - abilityPoints"
                                    :key="i + 'left'" class="movement-points movement-points-left"></span></div> End Round
                        </button>
                        <button class="btn btn-success btn-block btn-lg" v-if="currentEnemyIndex < enemies.length"
                            @click="nextEnemyTurn">Next Enemy</button>

                    </div>
                    <b-modal no-close-on-esc no-close-on-backdrop centered ref="modal-attack" v-model="showAttackModal"
                        hide-footer hide-header title="Attack Outcome" @hide="resetAttackModal">
                        <div class="modal-custom-header">Attack</div>

                        <div v-if="!abilityClicked && playerIsAttacking" class="text-center mb-4">
                            <button v-for="ability in attackAbilities" :key="ability.name" @click="initiateAttack(ability)"
                                class="btn btn-primary m-2" :class="{ 'not-enough': abilityPoints < ability.cost }">{{
                                    ability.cost }}</button>
                        </div>
                        <div class="row">

                            <div class="col-6">
                                <div class="battle-player-wrapper">
                                    <img src="@/assets/player.gif" alt="Player" class="battle-player" />
                                    <img :src="getPlayerWeaponImageSrc()" class="battle-weapon">
                                    <b-progress :value="gamestate.playerHealth" :max="gamestate.playerMaxHealth"
                                        variant="success" show-value class="player-battle-health"></b-progress>
                                </div>
                                <div class="text-center"
                                    v-if="attackResult.playerRoll1 !== null && attackResult.playerRoll2 !== null">
                                    <img :src="getPlayerDiceImageSrc(attackResult.playerRoll1)" alt="Player's Dice"
                                        class="dice-image mt-2 mb-2" />
                                    <img :src="getPlayerDiceImageSrc(attackResult.playerRoll2)" alt="Player's Dice"
                                        class="dice-image mt-2 mb-2" />
                                    <img v-if="attackResult.playerRoll3"
                                        :src="getPlayerDiceImageSrc(attackResult.playerRoll3)" alt="Player's Dice"
                                        class="dice-image mt-2 mb-2" />
                                </div>
                            </div>

                            <div class="col-6">
                                <div v-if="currentEnemy">
                                    <div class="battle-enemy-wrapper">
                                        <img :src="getImgUrlGif(currentEnemy.type)" :alt="currentEnemy.type"
                                            class="battle-enemy" />
                                        <img :src="getWeaponImageSrcCurrent(currentEnemy.weapon)" class="battle-weapon">
                                        <b-progress :value="currentEnemy.health" :max="currentEnemy.maxHealth"
                                            variant="danger" show-value class="enemy-battle-health"></b-progress>

                                    </div>
                                </div>

                                <div class="text-center"
                                    v-if="attackResult.enemyRoll1 !== null && attackResult.enemyRoll2 !== null">
                                    <img :src="getEnemyDiceImageSrc(attackResult.enemyRoll1)" alt="Enemy's Dice"
                                        class="dice-image mt-2 mb-2" />
                                    <img :src="getEnemyDiceImageSrc(attackResult.enemyRoll2)" alt="Enemy's Dice"
                                        class="dice-image mt-2 mb-2" />
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="text-center font-size-xxl" v-if="attackResult.outcome">{{ attackResult.outcome
                                }}</div>
                            </div>

                        </div>
                        <div v-if="!abilityClicked" class="modal-custom-close">
                            <b-button size="sm" variant="danger" @click="resetAttackModal()">x</b-button>
                        </div>
                    </b-modal>
                    <div class="map-container" :style="mapContainerStyle">
                        <div class="planet-map"
                            :style="{ 'grid-template-columns': 'repeat(' + mapSize + ', 64px)', 'grid-template-rows': 'repeat(' + mapSize + ', 64px)' }">
                            <div v-for="(row, rowIdx) in map" :key="rowIdx" class="map-row">
                                <div v-for="(tile, colIdx) in row" :key="colIdx" :class="[
                                    'map-tile',
                                    tile.biome,
                                    tile.obstacle,
                                    { 'highlighted': abilityPoints > 0 && isHighlightedTile(rowIdx, colIdx) },
                                    { 'visited': isTileVisited(rowIdx, colIdx) },
                                    { 'enemy-tile': isEnemyTile(rowIdx, colIdx) },
                                    { 'current': isPlayerTile(rowIdx, colIdx) },
                                    { 'moveable': activeTile === `${rowIdx}-${colIdx}` },
                                ]" @click="handleTileClick(rowIdx, colIdx)">

                                    <div v-if="isTileVisited(rowIdx, colIdx)">

                                        <div class="player-wrapper" :style="{ transform: `scaleX(${playerRotation})` }">
                                            <div :class="{ 'move-animation': isMoving }" class="player-tile"
                                                v-if="isPlayerTile(rowIdx, colIdx)">
                                                <img src="@/assets/player.gif" alt="Player" class="player" />
                                                <img :src="getPlayerWeaponImageSrc()" class="weapon">
                                                <b-progress :style="{ transform: `scaleX(${playerRotation})` }"
                                                    :value="gamestate.playerHealth" :max="gamestate.playerMaxHealth"
                                                    variant="success" class="player-health"></b-progress>

                                            </div>
                                        </div>

                                        <div class="enemy-wrapper"
                                            :style="{ transform: `scaleX(${getEnemyRotation(rowIdx, colIdx)})` }">
                                            <Transition name="move">
                                                <div v-if="isEnemyTile(rowIdx, colIdx)">

                                                    <div :id="`enemy-${rowIdx}-${colIdx}`"
                                                        :class="{ 'enemy-active-turn': isCurrentEnemyTurn(getEnemy(rowIdx, colIdx).id) }">
                                                        <img :src="getImgUrlGif(getEnemy(rowIdx, colIdx).type)"
                                                            :alt="getEnemy(rowIdx, colIdx).type" class="enemy" />
                                                        <img :src="getWeaponImageSrc(rowIdx, colIdx)" class="weapon">
                                                        <b-progress
                                                            :style="{ transform: `scaleX(${getEnemyRotation(rowIdx, colIdx)})` }"
                                                            :value="getEnemy(rowIdx, colIdx).health"
                                                            :max="getEnemy(rowIdx, colIdx).maxHealth" variant="danger"
                                                            class="enemy-health"></b-progress>

                                                    </div>
                                                </div>
                                            </Transition>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>

        </div>
    </div>
</template>
  
<script>

export default {
    props: {
        minimap: Boolean,
        gamestate: Object,
        enemy: Object,
        modal: Boolean,
        currentBiome: String,
    },
    data() {
        return {
            map: [],
            showNextRoundButton: true,
            enemyIdCounter: 0,
            mapSize: 3 + this.enemy.type.subEnemies.length,
            //mapSize: 9,
            mapWidth: null,
            focusEntity: 'player',
            focusEnemy: null,
            enemies: [],
            playerIsAttacking: false,
            activeTile: null,
            mapHeight: null,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            playerRotation: 1,
            abilityClicked: false,
            currentEnemy: null,
            isMoving: false,
            roundEnded: false,
            currentEnemyIndex: 0,
            player: {
                row: -1,
                col: -1
            },
            attackAbilities: [
                { name: 'Basic Attack', cost: 2 },
                { name: 'Power Strike', cost: 3, ability: 'extradice' },
            ],
            showAttackModal: false,
            attackResult: {
                playerRoll1: null,
                playerRoll2: null,
                playerRoll3: null,
                enemyRoll1: null,
                enemyRoll2: null,
                outcome: ''
            },
            visitedRange: 12,
            visitedTiles: [],
            abilityPoints: 5,
            abilityPointsMax: 5
        }
    },
    created() {
        window.addEventListener('resize', this.handleWindowResize);

        //window.addEventListener('keydown', this.handleKeyDown);
    },
    mounted() {
        this.generateMapIfLocalStorageEmpty();
        const savedPlayer = localStorage.getItem('battle_player');
        if (savedPlayer) {
            this.player = JSON.parse(savedPlayer);
        }
        const visitedTiles = localStorage.getItem('battle_visitedTiles');
        if (visitedTiles) {
            this.visitedTiles = JSON.parse(visitedTiles);
        }
    },
    computed: {
        mapContainerStyle() {
            return this.calculateMapContainerStyle(this.windowWidth, this.windowHeight);
        }
    },
    methods: {
        isCurrentEnemyTurn(enemyId) {
            return this.currentEnemy && this.currentEnemy.id === enemyId;
        },
        endRound() {
            this.roundEnded = true; // Mark the round as ended
            // Trigger enemy movements and attacks here
            this.enemyTurn();
        },
        enemyTurn() {
            this.currentEnemyIndex = 0; // Start with the first enemy
            if (this.enemies.length > 0) {
                this.processNextEnemyTurn(this.currentEnemyIndex); // Start with the first enemy's turn
            }
        },
        updateFocus(focusType, enemy = null) {
            this.focusEntity = focusType; // 'player' or 'enemy'
            if (focusType === 'enemy' && enemy) {
                this.focusEnemy = { row: enemy.row, col: enemy.col };
            } else {
                this.focusEnemy = null; // Reset when it's not an enemy's turn
            }
            // No need to manually update mapContainerStyle; it will reactively update
        },
        processNextEnemyTurn(enemyIndex) {
            if (enemyIndex < this.enemies.length) {
                const enemy = this.enemies[enemyIndex];
                this.currentEnemy = enemy;
                this.updateFocus('enemy', enemy);

                // Check if the enemy can already attack the player before moving.
                if (this.canEnemyAttackPlayer(enemy)) {
                    // If the enemy is already in attack range, initiate the attack.
                    this.initiateEnemyAttack(enemy).then(() => {
                        // After attacking, proceed to the next enemy's turn.
                        this.nextEnemyTurn();
                    });
                } else {
                    // If not in range, move towards the player.
                    this.moveTowardsPlayer(enemy, 4); // Assuming moveTowardsPlayer now handles recursive movement.
                }
            } else {
                // All enemies have taken their turn, proceed to the next phase of the game.
                this.prepareNextRound();
                this.updateFocus('player');
            }
        },
        getValidMovementTilesTowardsPlayer(enemy, playerPosition) {
            const directions = [[1, 0], [-1, 0], [0, 1], [0, -1]]; // Basic cardinal directions
            let validTiles = [];

            directions.forEach(([dx, dy]) => {
                const newRow = enemy.row + dx;
                const newCol = enemy.col + dy;
                if (this.isTileMoveable(newRow, newCol) && !this.isPlayerTile(newRow, newCol)) {
                    validTiles.push({ row: newRow, col: newCol });
                }
            });

            // Sort tiles by proximity to the player
            validTiles = validTiles.sort((a, b) => {
                const distA = Math.abs(a.row - playerPosition.row) + Math.abs(a.col - playerPosition.col);
                const distB = Math.abs(b.row - playerPosition.row) + Math.abs(b.col - playerPosition.col);
                return distA - distB;
            });

            return validTiles;
        },
        moveTowardsPlayer(enemy, movesRemaining) {
            if (movesRemaining === 0 || this.canEnemyAttackPlayer(enemy)) {
                // If no moves left or enemy is in attack range, stop moving
                return;
            }

            const playerPosition = { row: this.player.row, col: this.player.col };
            const moveableTiles = this.getValidMovementTilesTowardsPlayer(enemy, playerPosition);

            if (moveableTiles.length > 0) {
                const moveTo = moveableTiles[0]; // The first tile is the closest to the player
                this.moveEnemy(enemy, moveTo.row, moveTo.col).then(() => {
                    // Recursively call moveTowardsPlayer with one less move remaining
                    this.moveTowardsPlayer(enemy, movesRemaining - 1);
                });
            }
        },
        nextEnemyTurn() {
            this.currentEnemyIndex += 1;
            if (this.currentEnemyIndex < this.enemies.length) {
                this.processNextEnemyTurn(this.currentEnemyIndex);
            } else {
                // Reset for the next round or player's turn
                this.prepareNextRound();
            }
        },
        prepareNextRound() {
            // Reset the round
            this.updateFocus('player');
            this.roundEnded = false;
            this.abilityPoints = this.abilityPointsMax; // Reset ability points or to the appropriate value for the next round
            // Possibly regenerate some player/enemy health, move enemies, etc.
        },
        canAttackEnemy(rowIdx, colIdx) {
            // Use the weapon's range to check if the enemy is within attack range.
            // This assumes gamestate.weapon.range exists and is a number.
            const weaponRange = this.gamestate.weapon.range || 1; // Default to 1 if no range is defined
            return this.isWithinAttackRange(this.player.row, this.player.col, rowIdx, colIdx, weaponRange);
        },
        canEnemyAttackPlayer(enemy) {
            const distance = Math.max(Math.abs(enemy.row - this.player.row), Math.abs(enemy.col - this.player.col));
            return distance <= enemy.range;
        },
        getValidMovementTiles(enemy) {
            // Including diagonal directions: down-right, down-left, up-right, up-left
            const directions = [
                [1, 0], // Down
                [-1, 0], // Up
                [0, 1], // Right
                [0, -1], // Left
                [1, 1], // Down-Right
                [1, -1], // Down-Left
                [-1, 1], // Up-Right
                [-1, -1] // Up-Left
            ];
            const validTiles = [];

            directions.forEach(([dx, dy]) => {
                const newRow = enemy.row + dx;
                const newCol = enemy.col + dy;
                if (this.isTileMoveable(newRow, newCol) && !this.isPlayerTile(newRow, newCol)) {
                    validTiles.push({ row: newRow, col: newCol });
                }
            });

            return validTiles;
        },
        moveEnemy(enemy, newRow, newCol) {
            return new Promise((resolve) => {
                // Update enemy position
                enemy.row = newRow;
                enemy.col = newCol;

                // Simulate some delay for movement animation
                setTimeout(() => {
                    // Animation or movement logic goes here

                    // Resolve the promise to indicate movement is complete
                    resolve();
                }, 500); // Adjust delay based on actual animation time
            });
        },
        initiateEnemyAttack(enemy) {
            // Execute attack logic
            return new Promise((resolve) => {
                this.showBattle();
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.attackResult = {
                            playerRoll1: null,
                            playerRoll2: null,
                            playerRoll3: null,
                            enemyRoll1: null,
                            enemyRoll2: null,
                            outcome: ''
                        };

                        // First, roll for the enemy
                        this.attackResult.enemyRoll1 = this.rollDice();
                        this.attackResult.enemyRoll2 = this.rollDice();

                        // Wait a bit before rolling for the enemy to make it sequential
                        setTimeout(() => {
                            this.rollPlayerDice(enemy);
                        }, 1500);

                        // Logic to apply the outcome of the attack...
                        resolve();
                    }, 500);
                });
            });

        },
        isWithinAttackRange(currentRow, currentCol, targetRow, targetCol, range) {
            // Calculate the Chebyshev distance to check if the target is within the specified range
            const distance = Math.max(Math.abs(targetRow - currentRow), Math.abs(targetCol - currentCol));
            return distance <= range;
        },
        handleTileClick(rowIdx, colIdx) {
            // The logic remains largely the same, but now it's based on weapon range
            if (this.isEnemyTile(rowIdx, colIdx) && this.canAttackEnemy(rowIdx, colIdx)) {
                this.currentEnemy = this.getEnemy(rowIdx, colIdx);
                this.playerIsAttacking = true;
                this.showBattle();
            } else if (this.isHighlightedTile(rowIdx, colIdx) || this.canAttackEnemy(rowIdx, colIdx)) {
                // Allow for movement or attacking based on weapon range
                if (this.activeTile === `${rowIdx}-${colIdx}`) {
                    this.movePlayer(rowIdx, colIdx);
                    this.activeTile = null; // Reset active tile after moving player or attacking
                } else {
                    // Set this tile as active for movement or attack based on weapon range
                    this.activeTile = `${rowIdx}-${colIdx}`;
                }
            }
        },
        showBattle() {
            this.showAttackModal = true;
        },
        initiateAttack(ability) {

            // Ensure the UI has time to update. You might need to introduce a slight delay
            // before proceeding with the attack logic to allow the reset to be visually noticeable.
            this.$nextTick(() => {
                setTimeout(() => {
                    if (this.abilityPoints >= ability.cost) {
                        this.abilityPoints -= ability.cost;

                        this.abilityClicked = true; // Indicate that an ability has been clicked

                        this.attackResult = {
                            playerRoll1: null,
                            playerRoll2: null,
                            playerRoll3: null,
                            enemyRoll1: null,
                            enemyRoll2: null,
                            outcome: ''
                        };

                        // First, roll for the player
                        this.attackResult.playerRoll1 = this.rollDice();
                        this.attackResult.playerRoll2 = this.rollDice();
                        if (ability.ability == 'extradice') {
                            this.attackResult.playerRoll3 = this.rollDice();
                        }
                        // Wait a bit before rolling for the enemy to make it sequential
                        setTimeout(() => {
                            this.rollEnemyDice(ability);
                        }, 1500);

                        // Logic to apply the outcome of the attack...
                    } else {
                        console.log("Not enough ability points");
                    }
                }, 500);
            });

        },

        calculateOutcome(ability) {
            let damage = 0;
            let outcome = '';

            const distance = Math.max(Math.abs(this.currentEnemy.row - this.player.row), Math.abs(this.currentEnemy.col - this.player.col));
            const optimalRange = this.gamestate.weapon.range;
            const distanceFromOptimal = Math.abs(distance - optimalRange);
            const damageReductionPerTile = 0.2;

            // Early return for blocked attack
            if (this.attackResult.enemyRoll1 === this.attackResult.enemyRoll2) {
                console.log('Attack blocked');
                this.attackResult.outcome = 'blocked';
                return;
            }

            // Consolidate dice rolls and calculations
            const playerRollTotal = this.attackResult.playerRoll1 + this.attackResult.playerRoll2 + this.attackResult.playerRoll3;
            const enemyRollTotal = this.attackResult.enemyRoll1 + this.attackResult.enemyRoll2;

            // Determine baseIncrease based on weapon type
            const attributeMultiplier = this.gamestate.weapon.type === 'ranged' ? this.gamestate.playerAgility : this.gamestate.playerStrength;
            const baseIncrease = Math.round(this.gamestate.playerDmg * ((attributeMultiplier * 10) / 100));

            const diceIncrease = Math.round(this.gamestate.playerDmg * ((playerRollTotal * 10) / 100));

            // Determine if hit or miss
            if (playerRollTotal >= enemyRollTotal) {
                let critMultiplier = 1;
                let isCrit = false;

                // Check for critical hit conditions
                if ((ability.ability === 'extradice' && playerRollTotal === 3 * this.attackResult.playerRoll1) ||
                    (!ability.ability === 'extradice' && this.attackResult.playerRoll1 === this.attackResult.playerRoll2)) {
                    critMultiplier = 2; // Double damage for crit
                    isCrit = true;
                }

                // Base damage calculation
                let possibleDamage = this.gamestate.weapon.damage + this.gamestate.playerDmg + baseIncrease - this.currentEnemy.defense + diceIncrease;
                console.log(`Possible damage (before crit and reductions): ${possibleDamage}`);

                damage = possibleDamage * critMultiplier;

                // Apply distance-based reduction
                if (distanceFromOptimal > 0) {
                    const reductionFactor = 1 - (damageReductionPerTile * distanceFromOptimal);
                    damage = Math.round(damage * reductionFactor);
                    console.log(`Damage reduced to ${damage} due to distance from optimal range.`);
                } else {
                    console.log(`No distance-based reduction applied. Damage remains at ${damage}.`);
                }

                // Set outcome message
                outcome = isCrit ? `${damage} crit damage` : `${damage} damage`;
            } else {
                outcome = 'miss';
                console.log('Attack missed');
            }

            // Log final damage
            console.log(`Final damage: ${damage}`);

            // Apply damage if not a miss and update outcome
            if (damage > 0 && outcome !== 'miss') {
                this.applyDamageToEnemy(damage);
            }
            this.attackResult.outcome = outcome;
        },

        calculateEnemyOutcome(enemy) {
            let damage = 0;
            let outcome = '';

            // Early return for blocked attack
            if (this.attackResult.playerRoll1 === this.attackResult.playerRoll2) {
                console.log('Attack blocked');
                this.attackResult.outcome = 'blocked';
                return;
            }

            // Consolidate dice rolls and calculations
            const playerRollTotal = this.attackResult.playerRoll1 + this.attackResult.playerRoll2;
            const enemyRollTotal = this.attackResult.enemyRoll1 + this.attackResult.enemyRoll2;

            // Determine baseIncrease based on weapon type
            const attributeMultiplier = enemy.weaponType === 'ranged' ? enemy.agility : enemy.strength;
            const baseIncrease = Math.round(enemy.damage * ((attributeMultiplier * 10) / 100));

            const diceIncrease = Math.round(enemy.damage * ((enemyRollTotal * 10) / 100));

            // Determine if hit or miss
            if (enemyRollTotal >= playerRollTotal) {
                let critMultiplier = 1;
                let isCrit = false;

                // Check for critical hit conditions
                if (this.attackResult.enemyRoll1 === this.attackResult.enemyRoll2) {
                    critMultiplier = 2; // Double damage for crit
                    isCrit = true;
                }

                // Base damage calculation
                let possibleDamage = enemy.damage + baseIncrease - this.gamestate.playerDefense + diceIncrease;
                console.log(`Possible damage (before crit and reductions): ${possibleDamage}`);

                damage = possibleDamage * critMultiplier;

                // Set outcome message
                outcome = isCrit ? `${damage} crit damage` : `${damage} damage`;
            } else {
                outcome = 'miss';
                console.log('Attack missed');
            }

            // Log final damage
            console.log(`Final damage: ${damage}`);

            // Apply damage if not a miss and update outcome
            if (damage > 0 && outcome !== 'miss') {
                this.applyDamageToPlayer(damage);
            }
            this.attackResult.outcome = outcome;
        },

        rollEnemyDice(ability) {
            this.attackResult.enemyRoll1 = this.rollDice();
            this.attackResult.enemyRoll2 = this.rollDice();
            // Wait a bit before calculating and showing the outcome
            setTimeout(() => {
                this.calculateOutcome(ability);

                setTimeout(() => {
                    this.resetAttackModal();
                }, 1500); // Adjust delay as needed
            }, 1500); // Adjust delay as needed for enemy dice visibility

        },
        rollPlayerDice(enemy) {
            this.attackResult.playerRoll1 = this.rollDice();
            this.attackResult.playerRoll2 = this.rollDice();
            // Wait a bit before calculating and showing the outcome
            setTimeout(() => {
                this.calculateEnemyOutcome(enemy);

                setTimeout(() => {
                    this.resetAttackModal();
                }, 1500); // Adjust delay as needed
            }, 1500); // Adjust delay as needed for enemy dice visibility

        },
        applyDamageToEnemy(damage) {
            if (this.currentEnemy) {

                this.currentEnemy.health -= damage;
                if (this.currentEnemy.health <= 0) {
                    this.attackResult.outcome += `Enemy defeated`;
                    this.handleEnemyDefeat();
                } else {
                    // Update the enemy's health in the local state and localStorage
                    this.updateEnemyInStateAndStorage();
                }
            }
        },
        applyDamageToPlayer(damage) {
            this.editGameState('substractHealth', damage);
            if (this.gamestate.playerHealth <= 0) {
                this.attackResult.outcome += `Player defeated`;
                this.handlePlayerDefeat();
            }
        },
        handleEnemyDefeat() {
            // Example of removing the defeated enemy, adjust based on your enemy identification logic
            this.enemies = this.enemies.filter(enemy => enemy.health > 0);
            this.saveEnemiesToLocalStorage(); // Save updated enemies list to localStorage
        },

        handlePlayerDefeat() {
            localStorage.clear();
            location.reload();
        },

        updateEnemyInStateAndStorage() {
            // Assuming enemies' state is correctly bound to the component's data
            this.saveEnemiesToLocalStorage(); // Reflect changes in localStorage
        },

        rollDice() {
            return Math.floor(Math.random() * 6) + 1;
        },
        resetAttackModal() {
            this.$refs['modal-attack'].hide(),
                this.attackResult = {
                    playerRoll1: null,
                    playerRoll2: null,
                    playerRoll3: null,
                    enemyRoll1: null,
                    enemyRoll2: null,
                    outcome: ''
                };
            this.abilityClicked = false; // Reset to hide the dice animation
            this.playerIsAttacking = false;
            this.currentEnemy = null;
        },
        findSuitablePlayerStartPosition() {
            let suitablePositionFound = false;
            let tries = 0;
            let maxTries = 100; // Prevents infinite loop
            while (!suitablePositionFound && tries < maxTries) {
                let potentialRow = Math.floor(Math.random() * this.mapSize);
                let potentialCol = Math.floor(Math.random() * this.mapSize);

                let tile = this.map[potentialRow][potentialCol];
                let isTileOccupiedByEnemy = this.isEnemyTile(potentialRow, potentialCol);

                if (!tile.obstacle && !isTileOccupiedByEnemy) {
                    // Suitable position found
                    this.player.row = potentialRow;
                    this.player.col = potentialCol;
                    suitablePositionFound = true;
                }
                tries++;
            }
            if (!suitablePositionFound) {
                console.error("Failed to find a suitable starting position for the player");
            }
        },
        calculateMapContainerStyle(windowWidth, windowHeight) {
            let focusPositionX, focusPositionY;
            const mapTileSize = 64;
            const gap = 4;
            // Calculate focus based on the current focus entity
            if (this.focusEntity === 'player') {
                focusPositionX = this.player.col * (mapTileSize + gap);
                focusPositionY = this.player.row * (mapTileSize + gap);
            } else if (this.focusEntity === 'enemy' && this.focusEnemy) {
                focusPositionX = this.focusEnemy.col * (mapTileSize + gap);
                focusPositionY = this.focusEnemy.row * (mapTileSize + gap);
            } else {
                return {}; // Fallback in case focus is not set correctly
            }

            const offsetX = (windowWidth / 2) - focusPositionX - (mapTileSize / 2);
            const offsetY = (windowHeight / 2) - focusPositionY - (mapTileSize / 2);

            return {
                transform: `translate(${offsetX}px, ${offsetY}px)`,
                width: `${this.mapWidth * (mapTileSize + gap) - gap}px`,
                height: `${this.mapHeight * (mapTileSize + gap) - gap}px`,
            };
        },

        isEnemyTile(rowIdx, colIdx) {
            return this.enemies.some(
                (enemy) => enemy.row === rowIdx && enemy.col === colIdx
            );
        },
        getEnemy(rowIdx, colIdx) {
            const enemy = this.enemies.find(
                (enemy) => enemy.row === rowIdx && enemy.col === colIdx
            );
            return enemy ? enemy : '';
        },
        getEnemyRotation(rowIdx, colIdx) {
            const enemy = this.enemies.find(
                (enemy) => enemy.row === rowIdx && enemy.col === colIdx
            );
            return enemy ? enemy.rotation : '';
        },
        handleWindowResize() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        getImgUrlPng(name) {
            var images = require.context('../assets/', false, /\.png$/)
            return images('./' + name + ".png")
        },
        getImgUrlGif(name) {
            var images = require.context('../assets/', false, /\.gif$/)
            return images('./' + name + ".gif")
        },
        getBannerImgUrl(name) {
            var images = require.context('../assets/', false, /\.png$/)
            return images('./' + 'banner_' + name + ".png")
        },
        getWeaponImageSrc(rowIdx, colIdx) {
            const weapon = this.getEnemy(rowIdx, colIdx).weapon;
            return require('@/assets/weapon_' + weapon + '.png');
        },
        getPlayerWeaponImageSrc() {
            const weapon = this.gamestate.weapon.image;
            return require('@/assets/weapon_' + weapon + '.png');
        },
        getWeaponImageSrcCurrent(weapon) {
            var images = require.context('../assets/', false, /\.png$/)
            return images('./' + 'weapon_' + weapon + ".png")
        },
        getPlayerDiceImageSrc(diceValue) {
            return require(`@/assets/dice-player-${diceValue}.png`);
        },

        getEnemyDiceImageSrc(diceValue) {
            return require(`@/assets/dice-enemy-${diceValue}.png`);
        },
        editGameState(value, change) {
            this.$emit('editGameState', value, change)
        },
        generateMap() {
            this.mapWidth = this.mapSize;
            this.mapHeight = this.mapSize;
            const map = [];
            for (let y = 0; y < this.mapHeight; y++) {
                const row = [];
                for (let x = 0; x < this.mapWidth; x++) {
                    const biome = this.currentBiome;
                    let obstacle = null;

                    row.push({ biome, obstacle });
                }
                map.push(row);
            }

            this.map = map;
            this.setPlayerStartPosition();
            this.placeObstacles();
            this.setEnemiesPosition();
        },
        setPlayerStartPosition() {
            const savedPlayer = localStorage.getItem('battle_player');
            if (!savedPlayer) {
                // Optionally, place the player in the middle of the map for a balanced start
                this.findSuitablePlayerStartPosition();

                // Mark the player's tile and adjacent tiles as visited
                this.markTileAsVisited(this.player.row, this.player.col);
                this.markAdjacentTilesAsVisited(this.player.row, this.player.col);

                // Save the initial player position and visited tiles to localStorage
                localStorage.setItem('battle_player', JSON.stringify(this.player));
                localStorage.setItem('battle_visitedTiles', JSON.stringify(this.visitedTiles));
            }

        },
        placeObstacles() {
            // Your logic to place obstacles, modified to ensure the player is not surrounded
            for (let y = 0; y < this.mapHeight; y++) {
                for (let x = 0; x < this.mapWidth; x++) {
                    // Only attempt to place obstacles in tiles other than the player's starting position
                    if (!(x === this.player.col && y === this.player.row)) {
                        const obstacleSpawnChance = Math.random();
                        if (obstacleSpawnChance < 0.2) { // Adjust spawn chance as needed
                            // Calculate obstacle type based on random chance
                            const obstacleType = Math.random();
                            let obstacle;
                            if (obstacleType < 1 / 3) {
                                obstacle = 'obstacle crate';
                            } else if (obstacleType < 2 / 3) {
                                obstacle = 'obstacle stones';
                            } else {
                                obstacle = 'obstacle bush';
                            }

                            // Ensure placing the obstacle doesn't surround the player
                            if (!this.isPlayerSurrounded()) {
                                this.map[y][x].obstacle = obstacle;
                            }
                        }
                    }
                }
            }
        },


        isPlayerSurrounded() {
            const directions = [[1, 0], [0, 1], [-1, 0], [0, -1]]; // Represents down, right, up, and left
            let freePaths = 0;

            directions.forEach(([dx, dy]) => {
                const newX = this.player.col + dx;
                const newY = this.player.row + dy;

                if (newX >= 0 && newX < this.mapWidth && newY >= 0 && newY < this.mapHeight) {
                    if (!this.map[newY][newX].obstacle) {
                        freePaths += 1;
                    }
                }
            });

            return freePaths === 0; // Returns true if surrounded (no free paths)
        },
        setEnemiesPosition() {
            // Clear existing enemies
            this.enemies = [];

            // Explicitly mark the player's position as occupied
            const occupiedCoordinates = [{ row: this.player.row, col: this.player.col }];

            // Ensure no enemy spawns on the player's position or where there's an obstacle
            for (const subEnemy of this.enemy.type.subEnemies) {
                const { name, type, health, maxHealth, dmg, weapon, strength, defense, weaponType, range, agility } = subEnemy;
                let row, col;
                let positionFound = false;

                // Try finding a suitable spawn location
                while (!positionFound) {
                    row = Math.floor(Math.random() * this.mapSize);
                    col = Math.floor(Math.random() * this.mapSize);

                    // Check if the tile is occupied or has an obstacle
                    const isOccupiedOrObstacle = occupiedCoordinates.some(coord => coord.row === row && coord.col === col) ||
                        this.map[row][col].obstacle;

                    if (!isOccupiedOrObstacle) {
                        // Suitable position found; add enemy here
                        this.enemies.push({
                            id: this.enemyIdCounter++,
                            name,
                            type,
                            health,
                            maxHealth,
                            dmg,
                            row,
                            col,
                            weapon,
                            strength,
                            weaponType,
                            range,
                            defense,
                            agility,
                            rotation: Math.random() < 0.5 ? 1 : -1
                        });

                        // Mark this location as occupied to prevent future spawns here
                        occupiedCoordinates.push({ row, col });
                        positionFound = true;
                    }
                }
            }
        },
        movePlayer(rowIdx, colIdx) {
            if (this.abilityPoints > 0) {
                this.isMoving = true;

                const isMoveable = this.isTileMoveable(rowIdx, colIdx);
                const isAdjacent = this.isAdjacentTile(this.player.row, this.player.col, rowIdx, colIdx);

                if (isMoveable && isAdjacent) {
                    this.setPlayerPosition(rowIdx, colIdx);
                    this.markAdjacentTilesAsVisited(rowIdx, colIdx);
                    this.markTileAsVisited(rowIdx, colIdx);
                    this.abilityPoints -= 1; // Consume 1 ability point for the movement
                    // Additional logic for handling movement (e.g., marking tile as visited)
                } else {
                    console.log("Cannot move to this tile or not enough ability points");
                }
            } else {
                console.log("Not enough ability points to move");
            }
            setTimeout(() => {
                this.isMoving = false;
            }, 500);
        },
        isPlayerTile(rowIdx, colIdx) {
            return this.player.row === rowIdx && this.player.col === colIdx;
        },
        isTileMoveable(rowIdx, colIdx) {
            // Check if rowIdx and colIdx are within the bounds of the map
            if (
                rowIdx >= 0 && rowIdx < this.map.length &&
                colIdx >= 0 && colIdx < this.map[rowIdx].length
            ) {
                const tile = this.map[rowIdx][colIdx];
                // Check if tile exists, is not an obstacle, and is not occupied by an enemy
                const isOccupiedByEnemy = this.isEnemyTile(rowIdx, colIdx);
                return tile && !tile.obstacle && !isOccupiedByEnemy;
            }
            // Return false if rowIdx or colIdx is out of bounds or conditions are not met
            return false;
        },
        markTileAsVisited(rowIdx, colIdx) {
            if (!this.isTileVisited(rowIdx, colIdx)) {
                this.visitedTiles.push({ row: rowIdx, col: colIdx });
                this.saveVisitedTilesToLocalStorage();
            }
        },
        markAdjacentTilesAsVisited(rowIdx, colIdx) {
            const { visitedRange } = this;

            for (let i = rowIdx - visitedRange; i <= rowIdx + visitedRange; i++) {
                for (let j = colIdx - visitedRange; j <= colIdx + visitedRange; j++) {
                    // This will now include diagonal tiles
                    if (this.isTileWithinMapBounds(i, j) && !this.isTileVisited(i, j)) {
                        this.markTileAsVisited(i, j);
                    }
                }
            }
        },
        isTileWithinMapBounds(row, col) {
            return row >= 0 && row < this.mapSize && col >= 0 && col < this.mapSize;
        },
        isHighlightedTile(rowIdx, colIdx) {
            const currentRow = this.player.row;
            const currentCol = this.player.col;

            // Check for diagonal, vertical, and horizontal adjacency
            const isAdjacent = Math.abs(rowIdx - currentRow) <= 1 && Math.abs(colIdx - currentCol) <= 1;

            return isAdjacent;
        },
        saveVisitedTilesToLocalStorage() {
            localStorage.setItem('battle_visitedTiles', JSON.stringify(this.visitedTiles));
        },

        isTileVisited(rowIdx, colIdx) {
            return this.visitedTiles.some(
                (visitedTile) => visitedTile.row === rowIdx && visitedTile.col === colIdx
            );
        },
        isAdjacentTile(currentRow, currentCol, newRow, newCol) {
            // Allow movement to diagonally adjacent tiles in addition to directly adjacent tiles
            const isDiagonalOrAdjacent = Math.abs(newRow - currentRow) <= 1 && Math.abs(newCol - currentCol) <= 1;

            return isDiagonalOrAdjacent;
        },
        setPlayerPosition(rowIdx, colIdx) {
            this.player.row = rowIdx;
            this.player.col = colIdx;
            localStorage.setItem('battle_player', JSON.stringify(this.player));
        },
        generateMapIfLocalStorageEmpty() {
            if (!localStorage.getItem('battle_map')) {
                this.generateMap();
                this.saveMapToLocalStorage();
            } else {
                this.loadMapFromLocalStorage();
            }
        },
        saveMapToLocalStorage() {
            const mapJson = JSON.stringify(this.map);
            const mapSize = JSON.stringify(this.mapSize);
            const enemiesJson = JSON.stringify(this.enemies);

            localStorage.setItem('battle_map', mapJson);
            localStorage.setItem('battle_mapSize', mapSize);
            localStorage.setItem('battle_enemies', enemiesJson);

        },
        saveEnemiesToLocalStorage() {
            localStorage.setItem('battle_enemies', JSON.stringify(this.enemies));
        },
        loadMapFromLocalStorage() {
            const mapJson = localStorage.getItem('battle_map');
            const mapSize = localStorage.getItem('battle_mapSize');
            const enemiesJson = localStorage.getItem('battle_enemies');

            if (mapJson && mapSize) {
                this.map = JSON.parse(mapJson);
                this.mapSize = mapSize;
                this.enemies = JSON.parse(enemiesJson);

            }
        },
    },
};
</script>