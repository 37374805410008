<template>
  <div class="inventory">
    <ul>
      <li @mouseover="selected = index" :class="{ 'selected': selected === index }" v-for="(resource, index) in inventory"
        :key="index" type="button">
        <div :id="resource.name + '-' + index" @click="useItem(resource, index)">
          <Resource :resource="resource" />
        </div>
        <b-tooltip :target="resource.name + '-' + index" placement="bottom">
          <div class="inventory-item-name">{{ resource.name }}</div>
          <div class="inventory-item-type"><strong>type:</strong> {{ resource.type }}</div>
          <div class="inventory-item-quantity"><strong>Quality:</strong> <span :class="resource.quality">{{ resource.quality }}</span></div>
          <button @click="removeFromInventory(resource)" class="btn btn-danger btn-sm">Drop</button>

        </b-tooltip>
      </li>

      <li v-for="(slot, index) in (gamestate.inventoryMax - gamestate.inventory)" :key="'empty-' + index"
        class="inventory-empty">
        <div class="slot"></div>
      </li>
    </ul>
  </div>
</template>
  
<script>

import Resource from "./Resource.vue";

export default {
  props: {
    inventory: Array,
    gamestate: Object,
  },
  components: {
    Resource,
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    const savedSortingOrder = localStorage.getItem('inventorySortingOrder');
    if (savedSortingOrder) {
      this.sortedInventory = this.inventory.slice().sort((a, b) => savedSortingOrder.indexOf(a.type) - savedSortingOrder.indexOf(b.type));
    }
  },
  methods: {
    addToInventory(value) {
      this.$emit('addToInventory', value)
    },
    removeFromInventory(value) {
      this.$emit('removeFromInventory', value)
    },
    editGameState(value, change) {
      this.$emit('editGameState', value, change)
    },
    useItem(value, index) {
      this.selected = index
      if (this.selected == index) {
        if (value.add) {
          this.editGameState(value);
          this.removeFromInventory(value);
        }
      }
    },
  },
};
</script>
