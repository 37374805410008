<template>
    <div :class="resource.quality" class="inventory-item">
        <span class="inventory-item-img"><img :src="getImgUrl(resource.name)" :alt="resource.name" /></span> 
    </div>
    
</template>
  
<script>
export default {
    props: {
        resource: Object,
    },
    methods: {
        getImgUrl(name) {
            var images = require.context('../assets/', false, /\.png$/)
            return images('./' + name + ".png")
        }

    },
};
</script>